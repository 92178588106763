















































import {Component, Vue} from 'vue-property-decorator';
import DistinctCard from '@/shared/DistinctCard.vue';
import {loadHiddenWidgets, saveHiddenWidgets} from '@/home/store';
import HmCol from '@/shared/HmCol.vue';

@Component({
    components: {
        HmCol,
        DistinctCard,
    },
})
export default class DashboardSettings extends Vue {
    hiddenWidgets: string[] = [];
    created(): void {
        this.hiddenWidgets = loadHiddenWidgets();
    }

    onInput(widgetName: string, value: boolean): void {
        if (value) {
            const index = this.hiddenWidgets.findIndex(hw => hw === widgetName);
            if (index > -1) {
                this.hiddenWidgets.splice(index, 1);
            }
        } else {
            this.hiddenWidgets.push(widgetName);
        }

        saveHiddenWidgets(this.hiddenWidgets);
    }
}
