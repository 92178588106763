










import {Component, Mixins} from 'vue-property-decorator';
import DistinctCard from '@/shared/DistinctCard.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {api} from '@/shared/api';

interface OOOSettings {
    areOutOfOfficeEventsEnabled: boolean;
    isAutoresponderEnabled: boolean;
}

@Component({
    components: {
        DistinctCard,
    },
})
export default class OutOfOfficeSettings extends Mixins(ApiCall) implements OOOSettings {
    areOutOfOfficeEventsEnabled = false;
    isAutoresponderEnabled = false;

    private async getSettings(): Promise<OOOSettings> {
        const {data} = await api<OOOSettings>({
            url: 'Employees/settings',
            method: 'get',
        });

        return data;
    }

    private async saveSettings(settings: OOOSettings): Promise<void> {
        await api({
            url: 'Employees/settings',
            method: 'put',
            data: settings,
        });
    }

    async mounted(): Promise<void> {
        await this.apiCall(async () => {
            const {areOutOfOfficeEventsEnabled, isAutoresponderEnabled} = await this.getSettings();
            this.areOutOfOfficeEventsEnabled = areOutOfOfficeEventsEnabled;
            this.isAutoresponderEnabled = isAutoresponderEnabled;
        });
    }

    async onSettingsChange(): Promise<void> {
        const settings: OOOSettings = {
            isAutoresponderEnabled: this.isAutoresponderEnabled,
            areOutOfOfficeEventsEnabled: this.areOutOfOfficeEventsEnabled,
        };
        await this.apiCall(this.saveSettings, settings);
    }

}
