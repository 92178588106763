























import {Component, Mixins, Watch} from 'vue-property-decorator';
import {InputAttrs} from '@/shared/mixins/InputAttrs';
import HmCol from '@/shared/HmCol.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {addCalendar} from '@/calendars/store/endpoints';

@Component({
    components: {
        HmCol,
    },
})
export default class AddCalendar extends Mixins(InputAttrs, ApiCall) {
    icalUri = '';
    name = '';

    get valid(): boolean {
        return this.icalUri.trim() !== '' && this.name.trim() !== '';
    }

    async onSubmit(): Promise<void> {
        await this.apiCall(async () => {
            const created = await addCalendar({
                icalUri: this.icalUri.trim(),
                name: this.name.trim(),
            });
            this.$emit('addCalendar', created);

            this.icalUri = '';
            this.name = '';
        });
    }

    @Watch('error')
    onError(newValue: string): void {
        this.$emit('error', newValue);
    }
}
