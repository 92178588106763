



















import {Component, Vue} from 'vue-property-decorator';
import LangFlag from 'vue-lang-code-flags';

import {availableLanguages, loadLanguageAsync} from '@/plugins/i18n';
import DistinctCard from '@/shared/DistinctCard.vue';

@Component({
    components: {
        DistinctCard,
        LangFlag,
    },
})
export default class LangSettings extends Vue {
    get langList(): string[] {
        return availableLanguages;
    }

    async onLanguageClick(lang: string): Promise<void> {
        this.$vuetify.lang.current = lang;
        await loadLanguageAsync(lang);
    }
}
