
























import {Component, Vue} from 'vue-property-decorator';
import {MetaInfo} from 'vue-meta';

import LangSettings from '@/settings/LangSettings.vue';
import ThemeSettings from '@/settings/ThemeSettings.vue';
import AppScreen from '@/layout/AppScreen.vue';
import CalendarSettings from '@/calendars/CalendarSettings.vue';
import HmCol from '@/shared/HmCol.vue';
import OutOfOfficeSettings from '@/settings/OutOfOfficeSettings.vue';
import DashboardSettings from '@/settings/DashboardSettings.vue';

@Component({
    components: {
        DashboardSettings,
        AppScreen,
        HmCol,
        LangSettings,
        ThemeSettings,
        OutOfOfficeSettings,
        CalendarSettings,
    },
    metaInfo(): MetaInfo {
        return {
            title: `${this.$t('settings')} — HERMES`,
        };
    },
})
export default class SettingsScreen extends Vue {

}
