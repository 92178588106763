









import {Component, Vue, Watch} from 'vue-property-decorator';
import DistinctCard from '@/shared/DistinctCard.vue';

@Component({
    components: {
        DistinctCard,
    },
})
export default class ThemeSettings extends Vue {
    isDark = this.$vuetify.theme.dark;

    @Watch('isDark')
    onIsDarkChange(newValue: boolean): void {
        if (newValue) {
            localStorage.setItem('isDark', 'true');
        } else {
            localStorage.removeItem('isDark');
        }

        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
}
