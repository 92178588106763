import {api} from '@/shared/api';
import {
    CalendarEntry,
    CalendarsList,
    CalendarsListBE,
    CreateCalendarEntry,
} from '@/calendars/store/CalendarEntry';

export async function getCalendars(): Promise<CalendarsList> {
    const result = await api<CalendarsListBE>({
        method: 'get',
        url: 'Calendars',
    });

    return {
        custom: result.data.customCalendars.map((c: CalendarEntry) => {
            return {...c, isEditable: true};
        }),
        builtin: result.data.predefinedCalendars.map((c: CalendarEntry) => {
            return {...c, isEditable: false};
        }),
    };
}

export async function addCalendar(cal: CreateCalendarEntry): Promise<CalendarEntry> {
    const result = await api<CalendarEntry>({
        method: 'post',
        url: 'Calendars',
        data: cal,
    });

    return result.data;
}

export async function toggleCalendar(cal: CalendarEntry): Promise<void> {
    const action = cal.isActive ? 'deactivate' : 'activate';
    const subType = cal.isEditable ? 'custom' : 'predefined';

    await api<void>({
        method: 'post',
        url: `Calendars/${action}/${subType}/${cal.id}`,
    });
}

export async function deleteCalendar(cal: CalendarEntry): Promise<void> {
    await api<void>({
        method: 'delete',
        url: `Calendars/${cal.id}`,
    });
}
