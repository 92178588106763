













































import {Component, Mixins} from 'vue-property-decorator';

import DistinctCard from '@/shared/DistinctCard.vue';
import {CalendarEntry, CalendarsList} from '@/calendars/store/CalendarEntry';
import AddCalendar from '@/calendars/AddCalendar.vue';
import {ApiCall} from '@/shared/mixins/ApiCall';
import {
    deleteCalendar,
    getCalendars,
    toggleCalendar,
} from '@/calendars/store/endpoints';
import HmCol from '@/shared/HmCol.vue';

@Component({
    components: {
        HmCol,
        AddCalendar,
        DistinctCard,
    },
})
export default class CalendarSettings extends Mixins(ApiCall) {
    twoLists: CalendarsList = {
        custom: [],
        builtin: [],
    };

    mounted(): void {
        this.fetchData();
    }

    async fetchData(): Promise<void> {
        this.apiCall(async () => {
            this.twoLists = await getCalendars();
        });
    }

    create(entry: CalendarEntry): void {
        this.twoLists.custom.push(entry);
    }

    async drop(entry: CalendarEntry): Promise<void> {
        await this.apiCall(async () => {
            await deleteCalendar(entry);

            const index = this.twoLists.custom.indexOf(entry);
            if (index > -1) {
                this.twoLists.custom.splice(index, 1);
            }
        });
    }

    async toggleActive(entry: CalendarEntry): Promise<void> {
        await this.apiCall(async () => {
            await toggleCalendar(entry);
            const list = this.twoLists[entry.isEditable ? 'custom' : 'builtin'];
            const index = list.indexOf(entry);
            if (index > -1) {
                this.$set(list, index, {...entry, isActive: !entry.isActive });
            }
        });
    }
}
